<div class="flex flex-col justify-between h-full">
    <div class="chat_cont" #chatContent>
        <div class="chat_list h-full">
            <ng-container *ngFor="let item of directMessgage; let i = index" #chatItems>
                <div class="relative flex gap-3 items-center justify-center p-2" *ngIf="showDateLine(i)">
                    <p class="text-xs text-yellow dm-date">{{ item.createdAt | moment:'YYYY-MM-DD'}}</p>
                </div>
                <div class="relative flex gap-3 items-start p-2">
                    <div class="relative cursor-pointer">
                        <img class="w-10 h-10 rounded-full" src="/assets/images/apple-touch-icon.png" *ngIf="item.adm_idx">
                        <img class="w-10 h-10 rounded-full" [src]="currentUser?.profile_image" *ngIf="!item.adm_idx" [uidx]="item.uidx" profile>
                    </div>
                    <div class="hover_box">
                        <p class="text-xs text-place">
                            <b class="text-sm mr-1 text-sub cursor-pointer">
                                <span *ngIf="item.adm_idx">고객센터</span>
                                <span *ngIf="!item.adm_idx">{{currentUser?.nick}}</span>
                            </b> {{ item.createdAt | moment:'HH:mm'}}</p>
                        <div class="absolute right-4 top-3 flex gap-4 btn_box opacity-0">
                            <button class="basic-hover"><svg class="w-5 h-5"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Tip"></use></svg></button>
                            <button class="basic-hover"><svg class="w-5 h-5"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_At"></use></svg></button>
                        </div>
                        <!-- 메세지영역 -->
                        <div class="inline-flex p-3 bg-back rounded mt-1" *ngIf="item.message_type == 'IMAGE'">
                            <img [src]="item.message" alt="">
                        </div>
                        <div class="inline-flex p-3 bg-back rounded mt-1" *ngIf="item.message_type == 'GIPHY'">
                            <img [src]="getGiphyImage(item.message)" alt="">
                        </div>
                        <div class="inline-flex p-3 bg-back rounded mt-1" style="white-space:normal; word-break: break-all;" *ngIf="item.message_type == 'TEXT'">
                            {{ item.message }}
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="directMessgage.length == 0">
                <div class="flex flex-col justify-center h-full text-center">
                    <img class="w-48 mx-auto" src="/assets/custom_img/empty.webp"><div class="-mt-5 text-basic opacity-70">Oops! 아직 데이터가 없습니다!</div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="relative chat_bottom p-3">
        <app-loading />
        <div class="send-input relative overflow-x-hidden" [ngClass]="{'focus-on':chat_focus}" >
            <div class="relative">
                <input type="text" #chatInputEl class="form-control form-control-rounded pr-16" placeholder="보내신 메시지" [(ngModel)]="chat_input" (focus)="chat_focus=true" (keydown)="onEnter($event)">
                <div class="absolute top-3 right-2 ">
                    <button #chatInputEmoji class="basic-hover mr-1" (click)="showGiphy=true;showEmoji=false"><svg class="w-5 h-5"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_GIF"></use></svg></button>
                    <button #chatInputGiphy class="basic-hover" (click)="showEmoji=true;showGiphy=false;"><svg class="w-5 h-5"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Emjoy"></use></svg></button>
                </div>
            </div>
            <button type="button" class="btn btn-green send_btn" (click)="sendMessage()" #chatInputSend><svg class="w-5 h-5 fill-white"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Send"></use></svg></button>
        </div>
        <!-- 이모지 -->
        <div [ngClass]="{ 'hidden': !showEmoji }">
            <emoji-container (close)="showEmoji=false;chatInput.focus()"  (choice)="chat_input = chat_input + $event;" />
        </div>
        <!-- gif -->
        <div class="gif_wrap" [ngClass]="{ 'hidden': !showGiphy }">
            <giphy-container (close)="showGiphy=false"  (choice)="chat_input = $event; showGiphy=false;chatInput.focus()" />
        </div>
    </div>
    
</div>
