import { Component, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { LoadingService } from '../../../common/loading/loading.service';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../../../common/loading/loading.component';
import { MessageService } from '../../../common/message/message.service';
import {Clipboard} from "@angular/cdk/clipboard"

@Component({
    selector: 'app-referral-modal',
    standalone: true,
    imports: [
        CommonModule,
        LoadingComponent
    ],
    templateUrl: './referral-modal.component.html',
    styles: ``
})
export class ReferralModalComponent {
    referral_url:any = '관리자에게 문의 하세요.'

    constructor(
        private apiService: ApiService,
        private loadingService: LoadingService,
        private clipboard: Clipboard,
        private messageService: MessageService,
    ) {}

    ngOnInit() {
        this.fnGetData()
    }

    fnGetData() {
        this.loadingService.show()
        this.apiService.get('/referral/code', false).subscribe({
            next: (jsondata) => {
                this.loadingService.hide()
                if(jsondata.success) {
                    const protocol = (window as any).location.protocol;
                    const host = (window as any).location.hostname
                    let url = `${protocol}//${host}`;
                    if((window as any).location.port) url += `:${(window as any).location.port}`
                    this.referral_url = `${url}/?referral=${jsondata.data.referral_code}`
                }
            }, error: (err) => {
                this.loadingService.hide()
            }
        })
    }

    copyAddress() {
        this.clipboard.copy(this.referral_url)
        this.messageService.open('복사 되었습니다.')
    }
}
