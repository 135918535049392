<!-- 비밀번호 변경 모달 -->
<div class="modal-content overflow-hidden relative rounded" [ngClass]="{ 'on': modalTab == 'password_setting' }">

    <!-- 비밀번호 변경 -->
    <div class="modal-body bg-back relative rounded">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base"></p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="relative p-6 overflow-y-auto scrollbar h-[660px]">
            <div class="py-5">
                <div class="text-center font-medium mb-5">
                    <img class="w-10 mx-auto" src="/assets/custom_img/profile/password.png" alt="">
                    <p class="text-tit text-lg font-bold mt-1">비밀번호 변경</p>
                    <p class="mt-1">귀하의 안전을 위해 먼저 이전 비밀번호를 확인해야 합니다.</p>
                </div>
                <p>이전 비밀번호</p>
                <div class="relative mt-2">
                    <input type="password" class="form-control form-control-rounded bg-back2" placeholder="비밀번호 설정">
                    <button class="absolute right-3 top-[14px] basic-hover" (click)="passwordView($event)"><svg class="w-4 h-4"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_View"></use></svg></button>
                </div>

                <button class="btn-green w-full h-12 font-extrabold mt-5" (click)="modalInHandle('password_setting')">확인</button>
            </div>

            
        </div>
    </div>


    <!-- 비밀번호 설정 -->
    <div class="modal-body bg-back password_setting modal-in relative rounded" [ngClass]="{ 'open': modalTab == 'password_setting'}">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base"></p>
            </div>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="relative p-6 overflow-y-auto scrollbar h-[660px]">
            <div class="py-5">
                <div class="text-center font-medium mb-5">
                    <img class="w-10 mx-auto" src="/assets/custom_img/profile/password.png" alt="">
                    <p class="text-tit text-lg font-bold mt-1">비밀번호 설정</p>
                </div>
                <p>이전 비밀번호</p>
                <div class="relative mt-2">
                    <input type="password" class="form-control form-control-rounded bg-back2" placeholder="비밀번호 설정">
                    <button class="absolute right-3 top-[14px] basic-hover" (click)="passwordView($event)"><svg class="w-4 h-4"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_View"></use></svg></button>
                </div>

                <p class="mt-5">비밀번호 확인</p>
                <div class="relative mt-2">
                    <input type="password" class="form-control form-control-rounded bg-back2" placeholder="비밀번호 확인">
                    <button class="absolute right-3 top-[14px] basic-hover" (click)="passwordView($event)"><svg class="w-4 h-4"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_View"></use></svg></button>
                </div>

                <div class="flex items-center gap-1 mt-6 text-xs">
                    <svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg>
                    비밀번호 변경 후 재로그인이 필요합니다.
                </div>

                <button class="btn-green w-full h-12 font-extrabold mt-5">확인</button>
            </div>

            
        </div>
    </div>
    
</div>
