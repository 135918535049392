import { Component, EventEmitter, Output } from '@angular/core';
import { passwordView } from '../../../common/common';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-password-change-modal',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './password-change-modal.component.html',
  styles: ``
})
export class PasswordChangeModalComponent {

    
    modalTab = ''
    get passwordView() { return passwordView }

    modalInHandle($event:any) {
        this.modalTab = (this.modalTab == $event) ? '' : $event;
    }
}
