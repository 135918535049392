<!-- 스핀 후 당첨화면  -->
<div class="modal-content relative rounded">
    <img class="spin_img absolute animate-spin slow" src="/assets/custom_img/prize_spin.webp" alt="">
    <div class="modal-body relative spin_prize flex items-center justify-center">
        <img class="absolute coin_img" src="/assets/custom_img/prize_coin.webp" alt="">
        <div class="relative z-10 p-10 text-center">
            <div class="tit text-2xl font-bold">축하합니다</div>
            <div class="mt-4 cont text-base font-medium">You’ve got free money to bet on our games!</div>
            <div class="my-8 prize">{{ options.code }}: {{ options.amount }} </div>
            <button class="w-full h-12 btn-green font-bold" (click)="onClose.emit('close')">Claim Rewards</button>
        </div>
    </div>
</div>
