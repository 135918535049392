import { Component, EventEmitter, Output } from '@angular/core';
import { ModalService } from '../../../common/modal/modal.service';


@Component({
  selector: 'app-racing-rank-modal',
  standalone: true,
  imports: [],
  templateUrl: './racing-rank-modal.component.html',
  styles: ``
})
export class RacingRankModalComponent {
    
    get modal() { return this.modalService }
    constructor(
        private modalService: ModalService
    ) {
    }
}
