import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingComponent } from '../../common/loading/loading.component';
import { LoadingService } from '../../common/loading/loading.service';
import { ApiService } from '../../service/api.service';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';

@Component({
    selector: 'app-memo-read',
    standalone: true,
    imports: [
        CommonModule,
        LoadingComponent,
        MomentPipe
    ],
    templateUrl: './memo-read.component.html',
    styles: ``
})
export class MemoReadComponent {
    @Input() idx!:number;

    @Output() onList: EventEmitter<number> = new EventEmitter<number>();

    item:any
    constructor(
        private loadingService: LoadingService,
        private apiService: ApiService
    ) {}

    ngOnInit() {
        this.fnGetData()
    }

    fnGetData() {
        this.loadingService.show()
        this.apiService.get(`/memo/read/${this.idx}`, false).subscribe({
            next: (jsondata) => {
                if(jsondata.success) this.item = jsondata.data
                this.loadingService.hide()
            }, error: (err) => {
                this.loadingService.hide()
            }
        })

    }

    list() {
        this.onList.emit()
    }
}
