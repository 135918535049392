<div class="modal-content overflow-hidden relative rounded">
    <!-- 기본 -->
    <div class="modal-body relative">
        <div class="relative px-5 bg-modaldark rounded-t">
            <div class="flex items-center justify-between py-3">
                <p class="text-tit font-extrabold text-base">메모</p>
                <button class="basic-hover px-2 ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
            </div>
        </div>
    </div>
    <app-memo-list (onRead)="read($event)" [page]="page" *ngIf="!idx"/>
    <app-memo-read (onList)="list()" [idx]="idx" *ngIf="idx" />
</div>