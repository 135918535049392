import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService, currentUserInfo } from '../../auth/auth.service';
import { ApiService } from '../../../service/api.service';
import { Subscription } from 'rxjs';
import * as QRCode  from 'qrcode'
import { NgSelectDirective } from '@app/shared/lib/directive/ng-select.directive';
import { FilterPipe } from '@app/shared/lib/pipe/filter.pipe';
import {Clipboard} from "@angular/cdk/clipboard"
import { MessageService } from '../../../common/message/message.service';
import { LoadingService } from '../../../common/loading/loading.service';
import { LoadingComponent } from '../../../common/loading/loading.component';
import moment from 'moment';
import { WalletPipe } from '@app/shared/lib/pipe/wallet.pipe';
import { RemainDirective } from '@app/shared/lib/directive/remain.directive';
import { SettingsService } from '../../auth/settings.service';


@Component({
  selector: 'app-deposit-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectDirective,
    FilterPipe,
    LoadingComponent,
    RemainDirective,
    WalletPipe
  ],
  templateUrl: './deposit-modal.component.html',
  styles: ``
})
export class DepositModalComponent {
    private subs: Subscription[] = [];

    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    modalTab = ''
    tab = 'currency'
    
    form1!: FormGroup;
    submitted = false;
    sigining = false;

    is_show_charge: boolean = false;
    bonus: number = 0;
    is_first: boolean = false

    min_deposit!:number // 최소입금액

    bank_name!:string;  // 유저 출금은행
    bank_account!:string; // 유저 출금 계좌
    bank_owner!:string; // 유저 출금 예금주

    charge_bank_account!: string; // 입금 계좌
    charge_bank_name!: string;   // 입금 은행
    charge_bank_owner!: string; // 입금 예금주
    
    charge_mileage_max:number = 0; // 입금보너스 맥스
    charge_mileage_rate:number = 1; // 입금보너스 rate
    first_deposit_rate: number = 1; // 첫충전 곱하기 비율

    currentUser!:currentUserInfo | null

    keyword:string=''
    currentCrypto = 'BTC'
    currentNetwork = 'Segwit'
    cryptoItems:any = []
    cryptoNetworks:any = ['Segwit']
    currentRate!:number;
    currentRateCoin!:string;

    crypto_info:any = {}
    in_progress:any

    currency!:string

    @ViewChild('btnCrypto') btnCrypto!: ElementRef;

    get f() { return this.form1.controls }
    constructor(
        private formBuilder: FormBuilder,
        private decimalPipe: DecimalPipe,
        private authService: AuthService,
        private apiService: ApiService,
        private clipboard: Clipboard,
        private messageService: MessageService,
        private loadingService: LoadingService,
        private settingsService: SettingsService
    ) {
        this.form1 = this.formBuilder.group({
            wallet_code: [''],
            network: [''],
            is_crypto: ['0'],
            amount: ['0', Validators.required],
            is_bonus_rolling: [true, Validators.required ],
            is_first: ['0'],
            pwd: [''],
        });
    }

    ngOnInit(): void {
        this.subs.push(
            this.settingsService.getValue().subscribe(v => {
                if(v) this.currency = v.currency
            })
        )

        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        this.subs.push(
            this.form1.valueChanges.subscribe(form => {
                if(form.amount) {
                    const value = form.amount.toString().replace(/[^0-9]/g,"");
                    this.f['amount'].patchValue(this.decimalPipe.transform(value), { emitEvent: false });
                }
            })
        )

        this.fnGetData()

        // this.qrAddress = '0xbef975A42C979EA9bbFd0CC6F5EAb8FC79664325';
        // QRCode.toDataURL(this.qrAddress).then(url => {
        //     this.qrImage = url
        // })

        // this.qrExpiredAt = moment().add('+10', 'seconds')
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    modalInHandle($event:any) {
        this.modalTab = (this.modalTab == $event) ? '' : $event;
    }

    fnGetData() {
        this.apiService.get("/deposit/info-deposit", false).subscribe(jsondata => {
            if(jsondata.success) {

                this.min_deposit = +(jsondata.data.min_deposit);
                this.bank_name = jsondata.data.bank_name
                this.bank_account = jsondata.data.bank_account
                this.bank_owner = jsondata.data.bank_owner

                this.first_deposit_rate = +(jsondata.data.first_deposit_rate)
                this.charge_mileage_max = +(jsondata.data.charge_mileage_max)
                this.charge_mileage_rate = +(jsondata.data.charge_mileage_rate)
                this.is_first = jsondata.data.is_first_deposit

                this.cryptoItems = jsondata.data.crypto

                this.in_progress = jsondata.data.history;
                if(jsondata.data.history) {
                    if(jsondata.data.history['payment'] == 'FULGURPAY') {
                        const data = JSON.parse(jsondata.data.history['payment_info'])

                        this.crypto_info.crypto_currency = data.crypto_currency;
                        this.crypto_info.crypto_amount = data.crypto_amount;
                        this.crypto_info.address = data.address
                        this.crypto_info.chain_info = data.chain_info;
                        this.crypto_info.expiredAt = moment(jsondata.data.history['updatedAt']).add(data.timer, 'minutes')
                        QRCode.toDataURL(this.crypto_info.address).then(url => {
                            this.crypto_info.qrImage = url
                        })
                    }
                }
                

            }
            if(jsondata.message) (window as any).alert(jsondata.message);
        });
    }

    fnGetAccount() {
        if(!this.f['pwd'].value) {
            (window as any).alert('비밀번호를 입력하세요.')
            return;
        }
        this.loadingService.show()
        this.apiService.post('/deposit/request-bank', { pwd: this.f['pwd'].value}, false).subscribe({
            next: jsondata => {
                if(jsondata.success) {
                    // this.charge_bank_account = jsondata.data.charge_bank_account
                    // this.charge_bank_name = jsondata.data.charge_bank_name
                    // this.charge_bank_owner = jsondata.data.charge_bank_owner

                    // this.is_show_charge = true
                }

                if(jsondata.message) (window as any).alert(jsondata.message);
                this.loadingService.hide()
            }, error: err => {
                this.loadingService.hide()
            }
        })
    }

    fnSetAmount(amount:number) {
        const value = this.f['amount'].value.toString().replace(/[^0-9]/g,"");
        let total = (+value) + amount;

        this.f['amount'].patchValue(total)
        this.fnSetBonus()
    }

    fnSetBonus() {
        if(this.charge_mileage_rate == 1) {
            this.bonus = 0
            return;
        };

        if(this.f['is_bonus_rolling'].value !== true ) {
            this.bonus = 0
            return;
        }
        
        const value = +(this.f['amount'].value.toString().replace(/[^0-9]/g,""));
        if(!value || value == 0) {
            this.bonus = 0
            return;
        }

        const rate = (this.is_first) ? this.charge_mileage_rate * this.first_deposit_rate : this.charge_mileage_rate ;
        const max_bonus = (this.is_first) ? this.charge_mileage_max * this.first_deposit_rate : this.charge_mileage_max

        let bonus = Math.floor(value * rate / 100)
        if(!bonus) bonus = 0;
        if(bonus > max_bonus) bonus = max_bonus
        this.bonus = bonus;
    }

    onSubmit() {
        this.submitted = true;

        const value = +(this.f['amount'].value.toString().replace(/[^0-9]/g,""));
        if(value < this.min_deposit) this.f['amount'].setErrors({ 'check_error': `최소 입금액은 ${ this.decimalPipe.transform(this.min_deposit) } 원 입니다.` });

        if(this.form1.invalid) return;

        if(!confirm(`입금신청을 하시겠습니까?`)) return;
        this.sigining = true;

        this.f['wallet_code'].patchValue(this.currency)

        this.loadingService.show()
        this.apiService.post('/deposit/process', this.form1.value, false).subscribe({
            next: jsondata => {
                this.loadingService.hide()
                this.sigining = false;
                this.submitted = false;
                if(jsondata.message) (window as any).alert(jsondata.message);
                if(jsondata.success) {
                    (window as any).alert(`입금신청을 완료했습니다.`);
                    this.in_progress = jsondata.data.history;
                    this.onClose.emit('close')
                }
            }, error: err => {
                this.loadingService.hide()    
            }
        })
    }

    onCryptoSubmit() {
        if(!this.currentCrypto) {
            (window as any).alert('입금화폐를 선택해 주세요.')
            return;
        }

        this.submitted = true;

        const value = +(this.f['amount'].value.toString().replace(/[^0-9]/g,""));
        if(value < this.min_deposit) this.f['amount'].setErrors({ 'check_error': `최소 입금액은 ${ this.decimalPipe.transform(this.min_deposit) } 원 입니다.` });

        if(this.form1.invalid) return;

        if(!confirm(`입금신청을 하시겠습니까?`)) return;
        this.sigining = true;

        this.f['wallet_code'].patchValue(this.currentCrypto);
        this.f['network'].patchValue(this.currentNetwork)

        this.loadingService.show()
        this.apiService.post('/deposit/process', this.form1.value, false).subscribe({
            next: (jsondata) => {
                this.loadingService.hide()
                this.sigining = false;
                this.submitted = false;
                if(jsondata.message) (window as any).alert(jsondata.message);
                if(jsondata.success) {
                    this.in_progress = jsondata.data.history;
                    const fulgurpay = jsondata.data.fulgurpay
                    const crypto_info = {
                        crypto_currency: fulgurpay.crypto_currency,
                        crypto_amount: fulgurpay.crypto_amount,
                        address: fulgurpay.address,
                        chain_info: fulgurpay.chain_info,
                        expiredAt: moment().add(fulgurpay.timer, 'minutes'),
                        crypto_info: null
                    }
                    this.crypto_info = crypto_info;
                    
                    this.currentRateCoin = fulgurpay.crypto_amount;
                    this.currentRate = 1 / fulgurpay.rate;
                    QRCode.toDataURL(this.crypto_info.address).then(url => {
                        this.crypto_info.qrImage = url
                    })
                }
            }, error: (err) => {
                this.loadingService.hide()
            }
        })
    }

    onCancelProc() {
        if(!confirm(`입금신청을 취소 하시겠습니까?`)) return;
        this.loadingService.show()
        this.apiService.post('/deposit/cancel', { idx: this.in_progress['idx']}, false).subscribe({
            next: (jsondata) => {
                this.loadingService.hide()
                if(jsondata.success) {
                    this.in_progress = null
                    this.crypto_info = null
                }
                if(jsondata.message) (window as any).alert(jsondata.message)

            }, error: (err) => {
                this.loadingService.hide()
            }
        })
    }

    currencyRate() {
        const options = {
            amount: this.f['amount'].value,
            currency_from: this.currency,
            currency_to: this.currentCrypto
        }
        this.loadingService.show()
        this.apiService.post('/deposit/currency-rate', options, false).subscribe({
            next: (jsondata: any) => {
                if(jsondata.message) (window as any).alert(jsondata.message)
                if(jsondata.success) {
                    this.currentRateCoin = jsondata.data.amount
                    // 환율 계산
                    this.currentRate = 1 / jsondata.data.rate;
                }
                this.loadingService.hide()
            },
            error: (err: any) => {
                this.loadingService.hide()
            }
        });
    }

    setCtypto(crypto:string) {
        const item = this.cryptoItems.find((v:any) => v.code == crypto)
        if(item) {
            this.currentCrypto = item.code
            this.cryptoNetworks = item.network
            this.currentNetwork = item.network[0]
        }
    }
    

    OnSelect($event:any, item:any){
        const el = $event.target
        const parent = el.closest('.ng-select');
        if(parent) parent.classList.remove('on')

        this.currentNetwork = item
    }

    OnSelect2($event:any, item:any){
        const el = $event.target
        const parent = el.closest('.ng-select2');
        if(parent) parent.classList.remove('on')

        this.currentCrypto = item.code
        this.cryptoNetworks = item.network
        this.currentNetwork = item.network[0]
    }

    copyAddress(str:string) {
        this.clipboard.copy(str)
        this.messageService.open('복사 되었습니다.')
    }

    expired() {
        this.crypto_info.qrImage = '/assets/images/expired.jpg'
    }
}
