<!-- 롤링 모달 -->
<div class="modal-content">
    <div class="modal-body bg-stand relative rounded">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base">롤</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-4 py-6 max-h-[660px] overflow-y-auto scrollbar">
            <div class="text-base font-bold">규칙-데일리 롤링 대회</div>
            <div class="text-sub">2023. 12. 3. ~ 2023. 12. 4.</div>
            <div class="mt-2 text-xs">
                1. 콘테스트 상금 풀은 자금에 따라 크게 달라지며 더 많은 플레이어가 베팅할수록 그 금액도 커집니다. 현재 상금 풀은 콘테스트 페이지에 표시됩니다.<br/>
                2. 10 대부분의 베팅 플레이어는 상금 풀을 분할합니다.<br/>
                3. 이 대회는 다음 베팅을 지원합니다: XEN, MATIC, COP, APT, UAH, mDegenPass, IOTX, SHIB, KES, XOF, NEAR, mBAYC, WLD, HNT, FLOOR, IDR, RUNE, DOG, DGB, VNDC, BCD, BSV, NBX, BCH, SUI, CAKE, DOT, BCL, JST, BANANO, SUNOLD, BTCB, GMT, BTC, TWT, ARB, AVAX, TON, GMX, BTG, AMPL, XAF, UZS, mPunks, FTM, BTTOLD, ONE, JPEG, SUSHI, ALGO, KRW, ATOM, BIGTIME, WBTC, GODS, SAMO, SNX, KUMA, KGS, mMeka, MANA, mMfers, EUR, ROSE, WAXP, SATS, ARS, DAI, VTHO, MXN, USD, SOL, ETC, BNB, ICP, USTC, BLUR, ETH, CELO, KLAY, TOMO, ADA, ICX, PAR, VET, MMK, DOGE, GBP, USDC.e, USDT, HBAR, RVN, mClonex, DASH, NANO, TZS, WAVES, VND, WBNB, XRP, RUB, SNACK, FLOKI, EGP, TRX, NFT, AZN, JPY, MYR, SUNNEW, GHS, JOE, KAVA, SAND, AMP, AUD, BRL, mDoodles, BDT, AVC, UGX, THB, DCR, KZT, PEN, TUSD, NZD, USDT.e, EGLD, LTC, USDC, KAS, KHR, THETA, VSYS, ENJ, CRO, CRV, CLP, NEXO, TFUEL, LUNA, NEWBTT, AAVE, EURS, NGN, UNI, MDL, mAzuki, MAGIC, NOK, CAD, XLM, LINK, PHP, QTUM, YFI, XTZ, OP, KSM, ELON, GM, INR, TAMA, EOS, FIL, GST, WETH, BIT, AXE, GALA, BAT, APE, YGG, HEX, PEOPLE, AXS, XMR, ZIL, WCK, PKR<br/>
                4. 위의 모든 암호화폐로 베팅할 수 있으며 모두 현재 환율로 USDT로 전환됩니다.<br/>
                5. 모든 상품은 BCD에 발송됩니다.<br/>
                6. 경품은 콘테스트가 종료되면 공지사항 페이지를 통해 발송됩니다.<br/>
                7. KBC.GAME는 콘테스트의 어느 단계에서나 규칙을 위반한 플레이어를 제외할 권리를 보유합니다.<br/>
                8. KBC.GAME은(는) 단독 재량에 따라 사전 통지 없이 규칙과 조건을 변경할 권리를 보유합니다.<br/><br/>
                🌟🌟 행운이 함께하기를 바랍니다. 재미있게 즐겨 보세요! 🌟🌟
            </div>
            <div class="text-base font-bold mt-4">상금 계산 공식</div>
            <div class="mt-1 text-xs">
                1st위 - 50% 콘테스트 상금 풀 중 데일리<br/>
                2nd위 - 25% 콘테스트 상금 풀 중 데일리<br/>
                3rd위 - 12% 콘테스트 상금 풀 중 데일리<br/>
                4th위 - 6% 콘테스트 상금 풀 중 데일리<br/>
                5th위 - 3% 콘테스트 상금 풀 중 데일리<br/>
                6th위 - 1.5% 콘테스트 상금 풀 중 데일리<br/>
                7th위 - 0.9% 콘테스트 상금 풀 중 데일리<br/>
                8th위 - 0.7% 콘테스트 상금 풀 중 데일리<br/>
                9th위 - 0.5% 콘테스트 상금 풀 중 데일리<br/>
                10th위 - 0.4% 콘테스트 상금 풀 중 데일리<br/>
            </div>
        </div>
    </div>
</div>
