import { Directive, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Directive({
    selector: '[profile]',
    standalone: true,
  })
export class ProfileDirective {
    @Input() uidx!:number | undefined;

    constructor(
        private el: ElementRef,
    ) {
        const supports = 'loading' in HTMLImageElement.prototype;

        if(supports) {
            this.el.nativeElement.setAttribute('loading', 'lazy');
        }
    }
  
    @HostListener('error')
    onError() {
        let num:number;
        if(this.uidx) num = (this.uidx % 6) + 1; // 1~6
        else  num = Math.floor(Math.random() * (6 - 0) + 1)
        this.el.nativeElement.setAttribute('src', '/assets/custom_img/profile/head' + num + '.png');
    }
}