import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ModalService } from '../../common/modal/modal.service';
import { SpinService } from '../../service/spin.service';

@Component({
    selector: 'app-sign-out',
    standalone: true,
    imports: [],
    template: '',
})
export class SignOutComponent {
    constructor(
        private router: Router,
        private authService: AuthService,
        private modalService: ModalService,
        private spinService: SpinService
    ) { }

    ngOnInit(): void {
        this.modalService.closeAll()

        this.authService.signOut().subscribe({
            next: (res: any) => {
                this.router.navigateByUrl('/');
            },
            error: (err: any) => {
                this.router.navigateByUrl('/');
            }
        })
        

        // const locale = localStorage.getItem('LANG')
        // if(locale?.match(/ko/)) {
        //     this.router.navigateByUrl('/ko');    
        // } else {
        //     this.router.navigateByUrl('/');    
        // }
        
    }
}
