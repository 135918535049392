<!-- 채팅규칙 모달 -->
<div class="modal-content">
    <div class="modal-body bg-modaldark relative rounded">
        <div class="relative flex items-center justify-between p-4">
            <p class="text-tit font-extrabold text-base">채팅규칙</p>
            <button class="basic-hover" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-6 font-semibold">
            <p class="">1. 스팸을 보내거나 괴롭히거나 다른 사용자에게 불쾌감을 주지 마십시오!</p>
            <p class="mt-3">2. 대출, 비, 팁 및 두 배로 동전을 구걸하거나 요구하지 마십시오.</p>
            <p class="mt-3">3. 잠재적인 사기로 볼 수 있는 의심스러운 행동을 하지 않습니다.</p>
            <p class="mt-3">4. 어떠한 형태의 광고/거래/판매/구매 또는 서비스 제공에 참여하지 마십시오.</p>
            <p class="mt-3">5. URL 단축기를 사용하지 마십시오. 항상 원본 링크를 제출하십시오.</p>
            <p class="mt-3">6. 지정된 언어 대화방을 이용하세요..</p>
            <p class="mt-3">전체 규칙 목록은 포럼에서 찾을 수 있습니다.</p>
        </div>
    </div>
</div>
