import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpInterceptorFn,
  HttpHandlerFn,
} from '@angular/common/http';
import { Observable, switchMap  } from 'rxjs';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service'

export const JwtInterceptor:HttpInterceptorFn = (request:HttpRequest<unknown>, next:HttpHandlerFn):Observable<HttpEvent<unknown>> => {
    const cookieService = inject(CookieService)
    
    const access_token = cookieService.get('access_token')
    const refresh_token = cookieService.get('refresh_token')
    const twofa_token = cookieService.get('twofa_token')

    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const isUpdateTokenUrl = request.url.startsWith(environment.apiUrl + '/auth/update-token');
    const is2faUrl = request.url.startsWith(environment.apiUrl + '/auth/signin-2fa');
    
    let token = (!isUpdateTokenUrl) ? access_token : refresh_token
    if(is2faUrl) token = twofa_token
    

    if(token && isApiUrl) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    return next(request)
}


export const JwtInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
];
