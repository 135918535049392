<div class="modal mo_full" [ngClass]="{'iframe': component == 'iframe' }" tabindex="-1" aria-hidden="true" #modalElement style="z-index:80">
    <div class="modal-dialog" [ngClass]="this.css">
        <ng-template [ngIf]="component == 'iframe'">
            <app-iframe-modal (onClose)="close($event)" [options]="optParam" [eventEmitter]="eventEmitter" />
        </ng-template>
        <ng-template [ngIf]="component == 'sign-in'">
            <app-sign-in-modal (onClose)="close($event)" [options]="optParam" />
        </ng-template>
        <ng-template [ngIf]="component == 'sign-up'">
            <app-sign-up-modal (onClose)="close($event)" [options]="optParam" />
        </ng-template>
        <ng-template [ngIf]="component == 'quest'">
            <app-quest-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'spin'">
            <app-spin-modal  (onClose)="close($event)" />
        </ng-template>
        <ng-template [ngIf]="component == 'spin-rolling'">
            <app-spin-rolling-modal (onClose)="close($event)" [options]="optParam" />
        </ng-template>
        <ng-template [ngIf]="component == 'spin-rolling2'">
            <app-spin-rolling2-modal (onClose)="close($event)" [options]="optParam" />
        </ng-template>
        <ng-template [ngIf]="component == 'currency'">
            <app-currency-modal (onClose)="close($event)" [options]="optParam" />
        </ng-template>
        <ng-template [ngIf]="component == 'vip-club'">
            <app-vip-club-modal (onClose)="close($event)" [options]="optParam" />
        </ng-template>
        <ng-template [ngIf]="component == 'vip-level'">
            <app-vip-level-modal (onClose)="close($event)" [options]="optParam" />
        </ng-template>
        <ng-template [ngIf]="component == 'referral'">
            <app-referral-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'profile'">
            <app-profile-modal [options]="optParam" />
        </ng-template>
        <ng-template [ngIf]="component == 'deposit'">
            <app-deposit-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'withdraw'">
            <app-withdraw-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'bonus-deposit'">
            <app-bonus-deposit-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'bonus-info'">
            <app-bonus-info-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'bonus-roll'">
            <app-bonus-roll-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'bonus-bcd'">
            <app-bonus-bcd-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'rollover'">
            <app-rollover-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'rollover-detail'">
            <app-rollover-detail-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'racing-rank'">
            <app-racing-rank-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'referral-info'">
            <app-referral-info-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'referral-rule'">
            <app-referral-rule-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'commission-info'">
            <app-commission-info-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'rolling-info'">
            <app-rolling-info-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'betslip'">
            <app-betslip-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'detail-share'">
            <app-detail-share-modal [options]="optParam" />
        </ng-template>
        <ng-template [ngIf]="component == 'house-edge'">
            <app-house-edge-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'chat-info'">
            <app-chat-info-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'chat-rain'">
            <app-chat-rain-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'chat-coin'">
            <app-chat-coin-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'jb-info'">
            <app-jb-info-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'bcd-info'">
            <app-bcd-info-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'password-change'">
            <app-password-change-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'twofa-active'">
            <app-twofa-active-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'lottery-play'">
            <app-lottery-play-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'lottery-provably'">
            <app-lottery-provably-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'lottery-fair'">
            <app-lottery-fair-modal />
        </ng-template>
        <ng-template [ngIf]="component == 'memo'">
            <app-memo />
        </ng-template>
        <ng-template [ngIf]="component == 'message'">
            <app-message-modal [optParam]="optParam" />
        </ng-template>
    </div>    
</div>
