<div class="container_wrap">

    <div class="container md:!px-10 !px-0 pt-0 md:pt-6">

        <!-- 비주얼 -->
        <div class="vip_visual flex md:flex-row flex-col items-center h- md:h-80">
            <div class="w-full h-full p-5 md:pl-12 flex flex-col md:justify-center justify-between">
                <div class="md:text-left text-center">
                    <h3 class="text-tit text-3xl xl:text-4xl font-extrabold">독점적인 <span class="text-yellow">VIP</span> 혜택</h3>
                    <p class="mt-3 text-tit md:w-3/5 font-medium">지금 VIP 클럽에 가입하고 선물, 사은품 및 놀라운 기능을 받을 준비를 하십시오! 당신을 놀라게 할 더 높은 캐쉬백과 독점적인 보상의 스릴을 경험하세요.</p>
                </div>
                <div class="flex gap-2 mt-7">
                    <a href="/casino" class="flex items-center justify-center w-48 h-12 btn-green font-bold" >게임 시작</a>
                    <button class="w-48 h-12 border border-slate-900 text-tit font-bold rounded" (click)="modal.open($event, 'vip-club')">수익 보기</button>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 vip_con01 my-10">
            <div class="rounded p-5 pt-10">
                <img class="w-1/2 mx-auto" src="/assets/custom_img/vip/img_01.webp" alt="">
                <h4 class="text-tit mt-5 font-bold text-base">수수료없는 출금</h4>
                <p class="mt-1 text-xs">VIP 38에서 수수료 없는 인출 특전을 잠금 해제하고 추가 비용 없이 쉽게 상금을 인출하세요.</p>
            </div>
            <div class="rounded p-5 pt-10">
                <img class="w-1/2 mx-auto" src="/assets/custom_img/vip/img_02.webp" alt="">
                <h4 class="text-tit mt-5 font-bold text-base">럭키 스핀 보상</h4>
                <p class="mt-1 text-xs">레벨을 올려서 룰렛을 돌리면 럭키 스핀으로 더 큰 상품을 받을 수 있습니다.</p>
            </div>
            <div class="rounded p-5 pt-10">
                <img class="w-1/2 mx-auto" src="/assets/custom_img/vip/img_03.webp" alt="">
                <h4 class="text-tit mt-5 font-bold text-base">전용 VIP 호스트</h4>
                <p class="mt-1 text-xs">VIP 38에서 수수료 없는 인출 특전을 잠금 해제하고 VIP 호스트의 개인화된 관심과 독점적인 혜택을 즐기십시오.</p>
            </div>
            <div class="type02 rounded p-5 pt-10">
                <img class="w-1/2 mx-auto" src="/assets/custom_img/vip/img_04.webp" alt="">
                <h4 class="text-tit mt-5 font-bold text-base">놀라운 레벨 업 보너스</h4>
                <p class="mt-1 text-xs">놀라운 보상이 기다리고 있으며 레벨이 올라감에 따라 여러 번 잠금 해제됩니다.</p>
            </div>
            <div class="type02 rounded p-5 pt-10">
                <img class="w-1/2 mx-auto" src="/assets/custom_img/vip/img_05.webp" alt="">
                <h4 class="text-tit mt-5 font-bold text-base">재미있는 팁과 레인</h4>
                <p class="mt-1 text-xs">팁으로 감사를 표시하거나 코인 드롭으로 보상을 받을 수 있는 기회를 얻으세요.</p>
            </div>
        </div>

        <div class="">
            <h4 class="text-tit text-2xl font-bold mb-1">VIP 보상 내역</h4>
            <div class="bg-backlight p-3 rounded overflow-x-auto">
                <table class="table pad-s table-border table-border-type text-tit min-w-[800px]">
                    <colgroup>
                        <col width="10%">
                        <col width="10%">
                        <col width="16%">
                        <col width="16%">
                        <col width="16%">
                        <col width="16%">
                        <col width="16%">
                    </colgroup>
                    <thead>
                        <tr class="bg-back text-center font-bold">
                            <th colspan="2"></th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/bronze.png" alt="">브론즈</th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/silver.png" alt="">실버</th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/gold.png" alt="">골드</th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/platinum.png" alt="">플래티넘</th>
                            <th><img class="inline-flex w-6 mr-1" src="./assets/custom_img/profile/diamond.png" alt="">다이아몬드</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center">
                            <td rowspan="2" >실시간</td>
                            <td>회원롤링 %</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.6</td>
                            <td>0.65</td>
                            <td>0.7</td>
                        </tr>
                        <tr class="text-center">
                            <td>추천인롤링 %</td>
                            <td>0.1</td>
                            <td>0.1</td>
                            <td>0.1</td>
                            <td>0.15</td>
                            <td>0.2</td>
                        </tr>
                        <tr class="text-center">
                            <td rowspan="2" >스포츠</td>
                            <td>회원롤링 %</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.6</td>
                            <td>0.65</td>
                            <td>0.7</td>
                        </tr>
                        <tr class="text-center">
                            <td>추천인롤링 %</td>
                            <td>0.1</td>
                            <td>0.1</td>
                            <td>0.1</td>
                            <td>0.15</td>
                            <td>0.2</td>
                        </tr>
                        <tr class="text-center">
                            <td rowspan="2" >카지노</td>
                            <td>회원롤링 %</td>
                            <td>0.4</td>
                            <td>0.45</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.6</td>
                        </tr>
                        <tr class="text-center">
                            <td>추천인롤링 %</td>
                            <td>0.1</td>
                            <td>0.1</td>
                            <td>0.1</td>
                            <td>0.15</td>
                            <td>0.2</td>
                        </tr>
                        <tr class="text-center">
                            <td rowspan="2" >슬롯게임</td>
                            <td>회원롤링 %</td>
                            <td>0.4</td>
                            <td>0.45</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.6</td>
                        </tr>
                        <tr class="text-center">
                            <td>추천인롤링 %</td>
                            <td>0.1</td>
                            <td>0.1</td>
                            <td>0.1</td>
                            <td>0.15</td>
                            <td>0.2</td>
                        </tr>
                        <tr class="text-center">
                            <td rowspan="2" >미니게임</td>
                            <td>회원롤링 %</td>
                            <td>0.4</td>
                            <td>0.45</td>
                            <td>0.5</td>
                            <td>0.55</td>
                            <td>0.6</td>
                        </tr>
                        <tr class="text-center">
                            <td>추천인롤링 %</td>
                            <td>0.1</td>
                            <td>0.1</td>
                            <td>0.1</td>
                            <td>0.15</td>
                            <td>0.2</td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="2">최대 베팅 금액 %</td>
                            <td>100</td>
                            <td>120</td>
                            <td>150</td>
                            <td>200</td>
                            <td>200</td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="2">레벨업 룰렛 티켓</td>
                            <td class="text-center">-</td>
                            <td><svg class="w-5 h-5 my-3 fill-silver mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-gold mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-platinum mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="2">전용 이벤트</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td><svg class="w-5 h-5 my-3 fill-gold mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-platinum mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="2">전담 컨설던트</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td><svg class="w-5 h-5 my-3 fill-platinum mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="2">충환전 우선처리</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td><svg class="w-5 h-5 my-3 fill-diamond mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>



    <div class="bg-btndark py-10 ">
        <div class="container">
            <h4 class="text-tit text-2xl font-bold mb-1">자주 묻는 질문</h4>

            <div class="accordial_list mt-2 p-5 bg-backlight rounded">
                <div class="accordian_item">
                    <div class="title flex items-center justify-between p-3 text-sm text-tit font-bold cursor-pointer bg-back">
                        '레벨업 보너스'란?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden p-3 bg-modaldark font-medium md:text-sm text-xs">
                        플레이어에 대한 감사의 표시로 다음 레벨에 도달하면 레벨 업 보너스로 보상합니다. 플레이어가 레벨 38 이상에 도달하면 낮은 레벨에서 사용할 수 있던 일반 레벨 업 보너스보다 약간 높게 부스트했습니다.
                    </div>
                </div>

                <div class="accordian_item mt-2">
                    <div class="title flex items-center justify-between p-3 text-sm text-tit font-bold cursor-pointer bg-back">
                        '레인'이란?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden p-3 bg-modaldark font-medium md:text-sm text-xs">
                        하루 종일 채팅 대화에 참여하여 Constant Chat Rains 보너스의 일부로 무료 코인을 받으세요.<br/>
                        룰:<br/>
                        <ol class="list-decimal pl-5">
                            <li>4레벨 이상의 플레이어 6명을 무작위로 선택하여 6시간마다 채팅방에서 보상을 드립니다.</li>
                            <li>보너스는 통화에 따라 다릅니다.</li>
                            <li>플레이어는 레인 알고리즘에 의해 무작위로 선택됩니다. 더 많은 메시지를 보낸 플레이어는 보상을 받을 확률이 더 높습니다.</li>
                            <li>그러나 사용자가 메시지를 한 번만 보낸 경우에도 여전히 레인 보너스를 받을 수 있는 기회가 있습니다.</li>
                            <li>비 정보는 로봇에 의해 대화방으로 전송됩니다.</li>
                            <li>사용자당 하나 이상의 계정을 갖는 것은 금지됩니다. 레인을 받을 가능성이 낮아지고, BC는 최대 24시간이 소요될 수 있는 사기 방지 시스템의 수동 검토를 위해 인출을 중단합니다. 우리는 인출 처리를 보장하지 않으며 잔액이 있는 계정을 금지할 수 있습니다. 이미 두 개 이상의 계정이 있는 경우 라이브 지원에 연락하여 스스로 포기하십시오.</li>
                        </ol>
                        <p class="text-tit">예를들어,:</p>
                        <div class="mt-2 inline-flex">
                            <div class="mt-2 flex gap-2 rounded bg-backlight p-3">
                                <i class="block w-9 h-9 rounded-full overflow-hidden"><img src="/assets/custom_img/profile_img.png" alt=""></i>
                                <div>
                                    <div class="py-2">__BC_HOST__ <svg class="w-4 h-4 inline-flex fill-blue-500"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Official"></use></svg></div>
                                    <div class="bg-sponsor rounded p-3">
                                        <p class="text-tit">비가 오는 중이네요, 비를 맞은 사람들:</p>
                                        <p class="text-primary text-xs">&#64;Jachk: 888TRX<br/>&#64;Blrr: 888TRX<br/>&#64;Pokiuutt: 888TRX<br/>&#64;Liisdt: 888TRX<br/>&#64;Yoiohu: 888TRX<br/>&#64;Loidr: 888TRX</p>
                                        <p class="text-tit">축하합니다!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordian_item mt-2">
                    <div class="title flex items-center justify-between p-3 text-sm text-tit font-bold cursor-pointer bg-back">
                        '코인드랍'이란?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden p-3 bg-modaldark font-medium md:text-sm text-xs">
                        VIP 레벨 7에 도달하지 않고 '코인 드랍'을 잠금 해제하지 않은 경우, 공개 채팅에서 발생하는 코인을 잡으려면 적극적이고 신속하게 행동해야 합니다. 보상은 가장 빠른 참여자에게 돌아갑니다.<br/>
                        VIP 레벨 7에 도달하면 공개 채팅에서 다른 플레이어와 행운과 관대함을 나누기 전에 나만의 '코인 드랍'을 만들고 받는 사람의 양과 수량을 사용자 지정할 수 있습니다.
                    </div>
                </div>

                <div class="accordian_item mt-2">
                    <div class="title flex items-center justify-between p-3 text-sm text-tit font-bold cursor-pointer bg-back">
                        '팁'이란?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden p-3 bg-modaldark font-medium md:text-sm text-xs">
                        VIP 레벨 8에 도달하면 다른 플레이어에게 팁을 줄 수 있습니다. 팁은 KBC.GAME에서 감사를 표시하고 관대함을 다른 플레이어와 공유하는 방법입니다. 다른 플레이어에게 보낼 금액을 결정할 수 있습니다.
                    </div>
                </div>

                <div class="accordian_item mt-2">
                    <div class="title flex items-center justify-between p-3 text-sm text-tit font-bold cursor-pointer bg-back">
                        '재충전'이란?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden p-3 bg-modaldark font-medium md:text-sm text-xs">
                        '재충전'은 게임 활동에 따라 일주일 내내 플레이어에게 분할 보너스(재충전)를 보상하는 독특하고 흥미로운 기능입니다. 플레이어는 이러한 보너스를 청구하기 위해 다른 간격을 설정하여 계속 플레이할 수 있는 추가 인센티브를 제공할 수 있습니다. 이 기사는 자격, 청구 방법, 빈도 옵션, 계산 및 리차지 보상 구조에 대한 최신 업데이트를 포함하여 재충전에 대해 알아야 할 모든 것을 안내합니다.<br/>

                        기본적으로 '재충전'은 플레이어가 자신이 설정한 다른 간격으로 일주일 내내 분할 보너스(충전)를 받는 것을 의미합니다. 이 기능은 플레이어의 참여를 유지할 뿐만 아니라 게임 경험에 흥미를 더해줍니다.<br/>

                        충전 활성화 자격<br/>

                        재충전 활성화 자격을 얻으려면 플레이어는 다음 기준을 충족해야 합니다.<br/>
                        <ol class="list-decimal pl-5">
                            <li>VIP 레벨이 22 이상이어야 합니다.</li>
                            <li>지난 7일(24/7) 동안 최소 $1,000를 롤링 해야 합니다.</li>
                        </ol>
                        플레이어가 이러한 요구 사항을 충족하면 충전 활성화를 요청할 수 있습니다. 활성화되면 다음 7일 동안 재충전을 사용할 수 있습니다.<br/>

                        <a href="javascript:;" class="text-primary">도움말 센터에서 자세히 알아보기 <svg class="inline-flex w-3 h-3 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a>
                    </div>
                </div>

                <div class="accordian_item mt-2">
                    <div class="title flex items-center justify-between p-3 text-sm text-tit font-bold cursor-pointer bg-back">
                        '주간 캐쉬백’이란?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden p-3 bg-modaldark font-medium md:text-sm text-xs">
                        '주간 캐쉬백'은 VIP 22+ 플레이어를 위해 설계된 당사 플랫폼의 독점 특전입니다. 이름에서 알 수 있듯이 이 보너스는 일주일에 한 번 발행됩니다. VIP 레벨 22 이상 플레이어에게 매주 금요일 지급되는 자동 보너스입니다. 보너스의 크기는 금요일-목요일 기간 사이에 $1000의 최소 베팅 요구 사항과 함께 지난 주 동안 플레이어가 베팅한 금액에 따라 다릅니다. 주간 캐쉬백 계산은 동일하게 유지되며 유일한 차이점은 재충전 갱신과 일치하지 않을 수 있다는 것입니다.
                    </div>
                </div>

                <div class="accordian_item mt-2">
                    <div class="title flex items-center justify-between p-3 text-sm text-tit font-bold cursor-pointer bg-back">
                        '스포츠 주간 보너스'란?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden p-3 bg-modaldark font-medium md:text-sm text-xs">
                        VIP 레벨 22+ 플레이어를 위한 기존 주간 보너스 외에도 이제 스포츠 베터를 위한 추가 특전으로 BC 스포츠 클럽 주간 보너스를 도입하고 있습니다. 새로운 스포츠 보너스는 스포츠 베팅에만 해당되며 토요일부터 금요일까지 플레이어의 베팅 금액을 기준으로 계산되며 매주 토요일에 자동으로 분배됩니다. BC Sports Club 보너스 도입으로 VIP 레벨 22+ 플레이어는 이제 더 많은 보상과 짜릿한 스포츠 베팅 경험을 즐길 수 있습니다!<br/>
                        BC 스포츠 클럽은 스포츠북의 주간 베팅 금액에 따라 다양한 주말 보너스를 제공합니다.
                        <ul class="list-disc pl-5">
                            <li>토요일부터 금요일까지 $500 이상 베팅하고 $5 주말 보너스를 받으세요!</li>
                            <li>$2,500 이상 베팅하고 $30 주말 보너스를 받으세요!</li>
                            <li>$5,000 이상 베팅하고 $70 주말 보너스를 받으세요!</li>
                            <li>$10,000 이상 베팅하고 $150 주말 보너스를 받으세요!</li>
                        </ul>
                        이 보너스는 스포츠북의 모든 시장과 승률에 적용되어 큰 승리를 거둘 수 있는 더 많은 기회를 제공합니다!<br/>
                        주간 베팅은 이전 토요일 00:00시부터 금요일 23:59시(UTC)까지 계산됩니다.
                    </div>
                </div>

                
            </div>
        </div>

    </div>




    
    <!-- ** 푸터 시작 ** -->
    <!-- ** 푸터 끝 ** -->

</div>