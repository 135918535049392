import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule  } from '@angular/common';
import { SignInModalComponent } from '../../app/home/modal/sign-in-modal.component';
import { QuestModalComponent } from '../../app/home/modal/quest-modal.component';
import { SpinModalComponent } from '../../app/home/modal/spin-modal.component';
import { SpinRollingModalComponent } from '../../app/home/modal/spin-rolling-modal.component';
import { SpinRolling2ModalComponent } from '../../app/home/modal/spin-rolling2-modal.component';
import { CurrencyModalComponent } from '../../app/home/modal/currency-modal.component';
import { VipClubModalComponent } from '../../app/home/modal/vip-club-modal.component';
import { VipLevelModalComponent } from '../../app/home/modal/vip-level-modal.component';
import { ReferralModalComponent } from '../../app/home/modal/referral-modal.component';
import { ProfileModalComponent } from '../../app/home/modal/profile-modal.component';
import { DepositModalComponent } from '../../app/home/modal/deposit-modal.component';
import { BonusDepositModalComponent } from '../../app/home/modal/bonus-deposit-modal.component';
import { BonusInfoModalComponent } from '../../app/home/modal/bonus-info-modal.component';
import { BonusRollModalComponent } from '../../app/home/modal/bonus-roll-modal.component';
import { BonusBcdModalComponent } from '../../app/home/modal/bonus-bcd-modal.component';
import { RolloverModalComponent } from '../../app/home/modal/rollover-modal.component';
import { RolloverDetailModalComponent } from '../../app/home/modal/rollover-detail-modal.component';
import { RacingRankModalComponent } from '../../app/home/modal/racing-rank-modal.component';
import { ReferralInfoModalComponent } from '../../app/home/modal/referral-info-modal.component';
import { ReferralRuleModalComponent } from '../../app/home/modal/referral-rule-modal.component';
import { CommissionInfoModalComponent } from '../../app/home/modal/commission-info-modal.component';
import { RollingInfoModalComponent } from '../../app/home/modal/rolling-info-modal.component';
import { BetslipModalComponent } from '../../app/home/modal/betslip-modal.component';
import { DetailShareModalComponent } from '../../app/home/modal/detail-share-modal.component';
import { HouseEdgeModalComponent } from '../../app/home/modal/house-edge-modal.component';
import { ChatInfoModalComponent } from '../../app/home/modal/chat-info-modal.component';
import { ChatRainModalComponent } from '../../app/home/modal/chat-rain-modal.component';
import { ChatCoinModalComponent } from '../../app/home/modal/chat-coin-modal.component';
import { JbInfoModalComponent } from '../../app/home/modal/jb-info-modal.component';
import { BcdInfoModalComponent } from '../../app/home/modal/bcd-info-modal.component';
import { PasswordChangeModalComponent } from '../../app/home/modal/password-change-modal.component';
import { TwofaActiveModalComponent } from '../../app/home/modal/twofa-active-modal.component';
import { LotteryPlayModalComponent } from '../../app/home/modal/lottery-play-modal.component';
import { LotteryProvablyModalComponent } from '../../app/home/modal/lottery-provably-modal.component';
import { LotteryFairModalComponent } from '../../app/home/modal/lottery-fair-modal.component';
import { MessageModalComponent } from '../../app/home/modal/message-modal.component';
import { IframeModalComponent } from './iframe-modal.component';
import { MemoComponent } from '../../app/memo/memo.component';
import { SignUpModalComponent } from '../../app/home/modal/sign-up-modal.component';
import { WithdrawModalComponent } from '../../app/home/modal/withdraw-modal.component';


@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    CommonModule,
    IframeModalComponent,
    SignInModalComponent,
    SignUpModalComponent,
    QuestModalComponent,
    SpinModalComponent,
    SpinRollingModalComponent,
    SpinRolling2ModalComponent,
    CurrencyModalComponent,
    VipClubModalComponent,
    VipLevelModalComponent,
    ReferralModalComponent,
    ProfileModalComponent,
    DepositModalComponent,
    BonusDepositModalComponent,
    BonusInfoModalComponent,
    BonusRollModalComponent,
    BonusBcdModalComponent,
    RolloverModalComponent,
    RolloverDetailModalComponent,
    RacingRankModalComponent,
    ReferralInfoModalComponent,
    ReferralRuleModalComponent,
    CommissionInfoModalComponent,
    RollingInfoModalComponent,
    BetslipModalComponent,
    DetailShareModalComponent,
    HouseEdgeModalComponent,
    ChatInfoModalComponent,
    ChatRainModalComponent,
    ChatCoinModalComponent,
    JbInfoModalComponent,
    BcdInfoModalComponent,
    PasswordChangeModalComponent,
    TwofaActiveModalComponent,
    LotteryPlayModalComponent,
    LotteryProvablyModalComponent,
    LotteryFairModalComponent,
    MessageModalComponent,
    MemoComponent,
    WithdrawModalComponent
  ],
  templateUrl: './modal.component.html',
  styles: ``
})
export class ModalComponent {
    @Input() component: string = '';
    @Input() optParam:any = {}
    @Input() eventEmitter:EventEmitter<any> = new EventEmitter<any>()

    @Output() onOpen = new EventEmitter();
    @Output() onClose = new EventEmitter();

    @ViewChild('modalElement') modalEl!: ElementRef;

    css:string=''
    constructor(

    ) {
    }

    ngOnInit(): void {
        if(this.component =='iframe') this.css = this.optParam.modalCss || 'modal-xl h-auto overflow-y-auto scrollbar'
        else if(this.component == 'sign-in') this.css = 'login_modal modal-2lg h-auto'
        else if(this.component == 'sign-up') this.css = 'login_modal modal-2lg h-auto'
        else if(this.component == 'vip-club') this.css = 'modal-2lg overflow-y-auto scrollbar sm:max-h-[820px]'
        else if(this.component == 'bcd-info') this.css = 'modal-lg'
        else if(this.component == 'bonus-bcd') this.css = 'modal-lg'
        else if(this.component == 'commission-info') this.css = 'modal-base'
        else if(this.component == 'currency') this.css = 'modal-xl'
        else if(this.component == 'deposit') this.css = 'modal-lg'
        else if(this.component == 'withdraw') this.css = 'modal-lg'
        else if(this.component == 'jb-info') this.css = 'modal-lg'
        else if(this.component == 'racing-rank') this.css = 'modal-2lg'
        else if(this.component == 'referral-rule') this.css = 'modal-base'
        else if(this.component == 'rollover') this.css = 'modal-xl'
        else if(this.component == 'spin-rolling') this.css = 'spin-rolling-modal'
        else if(this.component == 'spin-rolling2') this.css = 'spin-rolling2-modal'
        else if(this.component == 'message') this.css = 'modal-sm'
        else if(this.component == 'memo') this.css = 'modal-lg'
    }


    ngAfterViewInit():void {
        const el = this.modalEl.nativeElement

        el?.addEventListener(`show.tw.modal`, (e:any) => {
            this.onOpen.emit('show')
        })

        el?.addEventListener(`hidden.tw.modal`, (e:any) => {
            this.onClose.emit('close')
        })

        const modal = (window as any).tailwind.Modal.getOrCreateInstance(el)
        modal.show()
    }
   
    close($event:any) {
        const el = this.modalEl.nativeElement
        const modal = (window as any).tailwind.Modal.getOrCreateInstance(el)
        if(modal) modal.hide($event)
    }

    dispose() {
        const el = this.modalEl.nativeElement
        const modal = (window as any).tailwind.Modal.getOrCreateInstance(el)
        if(modal) modal.hide()
    }
}
