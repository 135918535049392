<!-- JB 안내 모달 -->
<div class="modal-content">
    <div class="modal-body bg-stand relative rounded ">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base">JB에 대하여</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-4 pb-6 ">
            <div class="relative">
                <img src="/assets/custom_img/fiat.png" alt="">
                <a href="javascript:;" class="absolute left-11 bottom-1/3 text-white">컨트랙트 보기 <svg class="inline-flex w-4 h-4 fill-white"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a>
            </div>
            <div class="mt-4 bg-back p-4 font-medium">
                <span class="text-tit">JB 란?</span><br/><br/>

                JB 는 KBC.GAME 플랫폼의 독점 게임 화폐(자체 게임 화폐)입니다. VIP 레벨업, 럭키스핀 등 이벤트 보상으로 획득할 수 있습니다.<br/><br/>

                더 놀라운 JB 기능이 곧 출시될 예정입니다... 계속 지켜봐 주세요!
            </div>
        </div>
    </div>
</div>
