import {
    Component,
    AfterViewInit,
    ElementRef,
    Input,
    NgZone,
    Output,
    EventEmitter,
    OnDestroy,
    AfterContentInit,
    Renderer2,
    ViewEncapsulation,
  } from '@angular/core';
import { Subscription } from 'rxjs';

export interface TurnstileOptions {
    sitekey: string;
    action?: string;
    cData?: string;
    callback?: (token: string) => void;
    'error-callback'?: (errorCode: string) => void;
    'expired-callback'?: () => void;
    theme?: 'light' | 'dark' | 'auto';
    tabindex?: number;
    size?: 'normal' | 'compact',
    appearance?: 'always' | 'execute' | 'interaction-only';
}  
  
declare global {
    interface Window {
        onloadTurnstileCallback: () => void;
        turnstile: {
            render: (
                idOrContainer: string | HTMLElement,
                options: TurnstileOptions
            ) => string;
            reset: (widgetIdOrContainer: string | HTMLElement) => void;
            getResponse: (
                widgetIdOrContainer: string | HTMLElement
            ) => string | undefined;
            remove: (widgetIdOrContainer: string | HTMLElement) => void;
        };
    }
}

// https://jsfiddle.net/skjmz7o2/
// https://developers.cloudflare.com/turnstile/troubleshooting/testing/
@Component({
    selector: 'turnstile',
    template: ``,
    standalone: true,
    exportAs: 'turnstile',
    styles: [`:host ::ng-deep iframe { width: 100% !important; }`],
})
export class TurnstileComponent implements AfterViewInit, OnDestroy {
    private subs: Subscription[] = [];

    @Input() siteKey!: string;
    @Input() action?: string;
    @Input() cData?: string;
    @Input() theme?: 'light' | 'dark' | 'auto' = 'auto';
    @Input() tabIndex?: number;
    @Input() size?: 'normal' | 'compact' = 'normal';
    @Input() appearance?: 'always' | 'execute' | 'interaction-only' = 'always';
    
  
    @Input() turnstile!:EventEmitter<string | null>
    @Output() resolved = new EventEmitter<string | null>();
    @Output() errored = new EventEmitter<string | null>();
  
    private widgetId!: string;
    private script!:HTMLScriptElement
    private turnstileUrl: string = `https://challenges.cloudflare.com/turnstile/v0/api.js`;
  
    constructor(
      private elementRef: ElementRef<HTMLElement>,
      private zone: NgZone,
      private renderer2: Renderer2
    ) {
        (window as any).onloadTurnstileCallback = () => {
            if (!this.elementRef?.nativeElement) {
                console.log(`Turnstile nativeElement Error`)
                return;
            }

            if(this.widgetId) return;

            this.widgetId = (window as any).turnstile.render(
                this.elementRef.nativeElement,
                {
                    sitekey: this.siteKey,
                    theme: this.theme,
                    tabindex: this.tabIndex,
                    action: this.action,
                    cData: this.cData,
                    size: this.size,
                    callback: (token:string) => {
                        this.resolved.emit(token)
                    },
                    'error-callback': (errorCode: string) => {
                        console.log(errorCode)
                        this.errored.emit(errorCode)
                    },
                    'expired-callback': () => {
                        this.resetWidget()
                        // this.zone.run(() => this.resetWidget());
                    },
                }
            );

            this.renderer2.listen('window', 'message', (event) => {
                if(event.data.event !== 'init') {
                    return;
                }
                // const host = <HTMLElement>this.elementRef.nativeElement.childNodes[0]
                // const shadow = host.shadowRoot || host.attachShadow({ mode: 'open' });
                // const shadow = host.shadowRoot;

                // a.attachShadow({ mode: 'open' })
                // console.log(a.shadowRoot?.querySelector('iframe'));
                // console.log(shadowRoot)
                // const iframe = shadowRoot?.querySelector('iframe')
                // // const iframe = this.renderer2.selectRootElement(`#cf-chl-widget-${event.data.widgetId}`);


                // console.log(iframe)
            })
            
        };
    }
  
    ngAfterViewInit(): void {
        if(this.turnstile) {
            this.subs.push(
                this.turnstile.subscribe(v => {
                    if(v == 'reset') this.resetWidget()
                })
            )
        }

        this.script = document.createElement('script');
        this.script.src = `${this.turnstileUrl}?render=explicit&onload=onloadTurnstileCallback`;
        this.script.async = true;
        this.script.defer = true;
        // this.script.crossOrigin = 'anonymous';
        document.head.appendChild(this.script);
    }

    
  
    resetWidget() {
        if(this.widgetId) {
            this.resolved.emit(null);
            (window as any)['turnstile'].reset(this.widgetId);
        }
    }
  
    public ngOnDestroy(): void {
        this.subs.map(v => v.unsubscribe());

        if(this.script) this.script.remove()
        if(this.widgetId) {
            (window as any)['turnstile'].remove(this.widgetId);
        }
    }
}
