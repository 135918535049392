import { Routes } from '@angular/router';
import { MainComponent } from './home/main.component';
import { VipComponent } from './home/vip.component';
import { BonusComponent } from './home/bonus.component';
import { AffiliateComponent } from './affiliate/affiliate.component';
import { ErrorComponent } from './home/error.component';
import { HomeComponent } from './home/home.component';
import { RacingComponent } from './racing/racing.component';
import { SignOutComponent } from './auth/sign-out.component';
import { UpcomingComponent } from './home/upcoming.component';
import { AuthGuard } from './auth/auth.guard';
import { userRole } from './auth/auth.service';
import { RedirestComponent } from './home/redirest.component';

export const routes: Routes = [
    { 
        path: ``, component: HomeComponent,
        children: [
            { path: '', pathMatch: 'full', component: MainComponent },
            { path: `casino`, loadChildren: () => import(`./casino/casino.routes`).then(m => m.routes) },
            { path: `slots`, loadChildren: () => import(`./slots/slots.routes`).then(m => m.routes) },
            { path: `sports`, loadChildren: () => import(`./sports/sports.routes`).then(m => m.routes) },
            { path: `vsports`, loadChildren: () => import(`./vsports/vsports.routes`).then(m => m.routes) },
            { path: `powerball`, loadChildren: () => import(`./powerball/powerball.routes`).then(m => m.routes) },
            { path: `minigame`, loadChildren: () => import(`./minigame/minigame.routes`).then(m => m.routes) },
            { path: `vip`, component: VipComponent },
            { path: `bonus`, component: BonusComponent },
            { path: `affiliate`, component: AffiliateComponent },
            { path: `help`, loadChildren:() => import(`./help/help.routes`).then(m => m.routes) },
            { path: `sponsorship`, loadChildren: () => import(`./sponsorship/sponsorship.routes`).then(m => m.routes) },
            { path: `promotion`, loadChildren: () => import(`./promotion/promotion.routes`).then(m => m.routes) },
            { path: `wallet`, loadChildren: () => import(`./wallet/wallet.routes`).then(m => m.routes), runGuardsAndResolvers: 'pathParamsOrQueryParamsChange' , canActivate: [AuthGuard], data: { role: userRole.LEVEL1 } },
            { path: `settings`, loadChildren: () => import(`./settings/settings.routes`).then(m => m.routes), runGuardsAndResolvers: 'pathParamsOrQueryParamsChange' , canActivate: [AuthGuard], data: { role: userRole.LEVEL1 } },
        ]
    },
    { path: `upcoming`, component: UpcomingComponent },
    { path: `racing`, component: RacingComponent, loadChildren: ()=> import(`./racing/racing.routes`).then(m => m.routes) },
    { path: `signout`, redirectTo: 'auth/signout'  },
    { path: `auth/signout`, component: SignOutComponent  },
    { path: `redirect`, component: RedirestComponent },
    { path: '**', component: ErrorComponent },
];


