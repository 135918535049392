import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService, currentUserInfo } from '../../auth/auth.service';
import { ApiService } from '../../../service/api.service';
import { Subscription } from 'rxjs';
import * as QRCode  from 'qrcode'
import { NgSelectDirective } from '@app/shared/lib/directive/ng-select.directive';
import { FilterPipe } from '@app/shared/lib/pipe/filter.pipe';
import {Clipboard} from "@angular/cdk/clipboard"
import { MessageService } from '../../../common/message/message.service';
import { LoadingService } from '../../../common/loading/loading.service';
import { LoadingComponent } from '../../../common/loading/loading.component';
import moment from 'moment';
import { WalletPipe } from '@app/shared/lib/pipe/wallet.pipe';
import { RemainDirective } from '@app/shared/lib/directive/remain.directive';
import { SettingsService } from '../../auth/settings.service';


@Component({
  selector: 'app-withdraw-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectDirective,
    FilterPipe,
    LoadingComponent,
    RemainDirective,
    WalletPipe
  ],
  templateUrl: './withdraw-modal.component.html',
  styles: ``,
  providers: [WalletPipe]
})
export class WithdrawModalComponent {
    private subs: Subscription[] = [];

    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    modalTab = ''
    tab = 'currency'
    
    form1!: FormGroup;
    submitted = false;
    sigining = false;

    currentUser!:currentUserInfo | null

    in_progress:any
    min_withdraw!:number        // 최소 출금액

    keyword:string=''
    currentCrypto = 'BTC'
    currentNetwork = 'Segwit'
    cryptoItems:any = []
    cryptoNetworks:any = ['Segwit']
    currentRateCoin!:string;

    walletInfo:any = []
    withdraw_address!:string | null

    currency!:string

    @ViewChild('btnCrypto') btnCrypto!: ElementRef;

    get f() { return this.form1.controls }
    constructor(
        private formBuilder: FormBuilder,
        private decimalPipe: DecimalPipe,
        private authService: AuthService,
        private apiService: ApiService,
        private loadingService: LoadingService,
        private walletPipe: WalletPipe,
        private settingsService: SettingsService
    ) {
        this.form1 = this.formBuilder.group({
            wallet_code: [''],
            network: [''],
            is_crypto: ['0'],
            amount: ['0', Validators.required],
            is_bonus_rolling: [true, Validators.required ],
            is_first: ['0'],
            withdraw_address: [''],
        });
    }

    ngOnInit(): void {
        this.subs.push(
            this.settingsService.getValue().subscribe(v => {
                if(v) this.currency = v.currency
            })
        )

        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        this.subs.push(
            this.form1.valueChanges.subscribe(form => {
                if(form.amount) {
                    const value = form.amount.toString().replace(/[^0-9]/g,"");
                    this.f['amount'].patchValue(this.decimalPipe.transform(value), { emitEvent: false });
                }
            })
        )

        this.fnGetData()

        // this.qrAddress = '0xbef975A42C979EA9bbFd0CC6F5EAb8FC79664325';
        // QRCode.toDataURL(this.qrAddress).then(url => {
        //     this.qrImage = url
        // })

        // this.qrExpiredAt = moment().add('+10', 'seconds')
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    modalInHandle($event:any) {
        this.modalTab = (this.modalTab == $event) ? '' : $event;
    }

    fnGetData() {
        this.apiService.get("/withdraw/info-withdraw", false).subscribe(jsondata => {
            if(jsondata.success) {
                this.min_withdraw = Number(jsondata.data.min_withdraw);
                this.in_progress = jsondata.data.history;
                this.cryptoItems = jsondata.data.crypto;
                this.walletInfo = jsondata.data.wallet_info;
            }

            if(jsondata.message) (window as any).alert(jsondata.message);
        });
    }


    fnSetAmount(amount:number) {
        const value = this.f['amount'].value.toString().replace(/[^0-9]/g,"");
        let total = (+value) + amount;

        this.f['amount'].patchValue(total)
    }


    onSubmit() {
        this.submitted = true;

        const value = +(this.f['amount'].value.toString().replace(/[^0-9]/g,""));
        if(value < this.min_withdraw) {
            this.f['amount'].setErrors({ 'check_error': `최소 출금액은 ${ this.decimalPipe.transform(this.min_withdraw) }원 입니다.` });
            return;
        }

        const balance = this.walletPipe.transform(this.currentUser?.Wallets, this.currency)
        if(value >  Number(balance)) {
            this.f['amount'].setErrors({ 'check_error': `보유금액은 ${ this.decimalPipe.transform(balance) }원 입니다.` });
            return;
        }

        this.f['wallet_code'].patchValue(this.currency)
        if(this.form1.invalid) return;

        // if(!confirm(`출금신청을 하시겠습니까?`)) return;
        this.sigining = true;

        this.loadingService.show()
        this.apiService.post('/withdraw/process', this.form1.value, false).subscribe({
            next: jsondata => {
                this.loadingService.hide()
                this.sigining = false;
                this.submitted = false;
                if(jsondata.message) (window as any).alert(jsondata.message);
                if(jsondata.success) {
                    (window as any).alert(`입금신청을 완료했습니다.`);
                    // this.onClose.emit('close')
                }
            }, error: err => {
                this.loadingService.hide()    
            }
        })
    }

    onCryptoSubmit() {
        if(!this.currentCrypto) {
            (window as any).alert('출금화폐를 선택해 주세요.')
            return;
        }

        this.submitted = true;

        const value = +(this.f['amount'].value.replace(/[^0-9]/g,""));
        if(value < this.min_withdraw) {
            this.f['amount'].setErrors({ 'check_error': `최소 출금액은 ${ this.decimalPipe.transform(this.min_withdraw) } 원 입니다.` });
            return;
        }

        const balance = this.walletPipe.transform(this.currentUser?.Wallets, this.currency)
        if(value >  Number(balance)) {
            this.f['amount'].setErrors({ 'check_error': `보유금액은 ${ this.decimalPipe.transform(balance) }원 입니다.` });
            return;
        }

        if(this.form1.invalid) return;

        // if(!confirm(`출금신청을 하시겠습니까?`)) return;
        this.sigining = true;

        this.f['wallet_code'].patchValue(this.currentCrypto);
        this.f['network'].patchValue(this.currentNetwork)

        this.loadingService.show()
        this.apiService.post('/withdraw/process', this.form1.value, false).subscribe({
            next: (jsondata) => {
                this.loadingService.hide()
                this.sigining = false;
                this.submitted = false;
                if(jsondata.message) (window as any).alert(jsondata.message);
                if(jsondata.success) {
                    this.in_progress = jsondata.data.history;
                }
            }, error: (err) => {
                this.loadingService.hide()
            }
        })
    }


    onCancelProc() {
        // if(!confirm(`출금신청을 취소 하시겠습니까?`)) return;
        this.loadingService.show()
        this.apiService.post('/withdraw/cancel', { idx: this.in_progress['idx']}, false).subscribe({
            next: (jsondata) => {
                this.loadingService.hide()
                if(jsondata.success) {
                    this.in_progress = null
                }
                if(jsondata.message) (window as any).alert(jsondata.message)

            }, error: (err) => {
                this.loadingService.hide()
            }
        })
    }

    SetWithdrawAddress() {

    }


    setCtypto(crypto:string) {
        const item = this.cryptoItems.find((v:any) => v.code == crypto)
        if(item) {
            this.currentCrypto = item.code
            this.cryptoNetworks = item.network
            this.currentNetwork = item.network[0]
        }
    }
    

    OnSelect($event:any, item:any){
        const el = $event.target
        const parent = el.closest('.ng-select');
        if(parent) parent.classList.remove('on')
        
        this.currentNetwork = item

        this.check_withdraw_address()
    }

    OnSelect2($event:any, item:any){
        const el = $event.target
        const parent = el.closest('.ng-select2');
        if(parent) parent.classList.remove('on')

        this.currentCrypto = item.code
        this.cryptoNetworks = item.network
        this.currentNetwork = item.network[0]

        this.check_withdraw_address()
    }

    check_withdraw_address() {
        const wallet = this.walletInfo.find((v:any) => v['wallet_code'] == this.currentCrypto)
        if(!wallet) {
            this.withdraw_address = null
        } else {
            const addr = JSON.parse(wallet['crypto_address'])
            if(!addr) this.withdraw_address = null
            else {
                console.log(addr[this.currentNetwork])
                this.withdraw_address = addr[this.currentNetwork]
            }
        }
    }
}
