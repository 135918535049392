import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalService } from '../../../common/modal/modal.service';
import { AuthService, currentUserInfo } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/shared/lib/directive/swiper.directive';
import { ApiService } from '../../../service/api.service';
import { ProfileDirective } from '@app/shared/lib/directive/profile.directive';
import { SpinService } from '../../../service/spin.service';

@Component({
  selector: 'app-spin-modal',
  standalone: true,
  imports: [
    CommonModule,
    SwiperDirective,
    ProfileDirective
  ],
  templateUrl: './spin-modal.component.html',
  styles: ``,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SpinModalComponent {
    private subs: Subscription[] = [];
    
    modalTab = ''
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    onModalEvent: EventEmitter<any> = new EventEmitter<any>()

    currentUser!: currentUserInfo | null
    total_hit!:number

    sigining:boolean = false;
    spin = 'LUCKY'
    spinData = {
        LUCKY: [
            { code: 'BCD', amount: 100, rotate: 45 },
            { code: 'BCD', amount: 100, rotate: 135 },
            { code: 'BCD', amount: 100, rotate: 225 },
            { code: 'BCD', amount: 100, rotate: 337.5 },
            { code: 'CRO', amount: 300, rotate: 292.5 },
            { code: 'USDC', amount: 500, rotate: 22.5 },
            { code: 'XLM', amount: 1000, rotate: 67.5 },
            { code: 'BNB', amount: 3000, rotate: 180 },
            { code: 'SHIB', amount: 5000, rotate: 315 },
            { code: 'SOL', amount: 10000, rotate: 157.5 },
            { code: 'TRX', amount: 30000, rotate: 270 },
            { code: 'DOGE', amount: 50000, rotate: 202.5 },
            { code: 'XRP', amount: 100000, rotate: 112.5 },
            { code: 'USDT', amount: 300000, rotate: 247.5 },
            { code: 'ETH', amount: 500000, rotate: 90 },
            { code: 'BTC', amount: 1000000, rotate: 0 },
        ],
        SUPER: [
            { code: 'BCD', amount: 300, rotate: 45 },
            { code: 'BCD', amount: 300, rotate: 135 },
            { code: 'BCD', amount: 300, rotate: 225 },
            { code: 'BCD', amount: 300, rotate: 337.5 },
            { code: 'CRO', amount: 500, rotate: 292.5 },
            { code: 'USDC', amount: 1000, rotate: 22.5 },
            { code: 'XLM', amount: 3000, rotate: 67.5 },
            { code: 'BNB', amount: 5000, rotate: 180 },
            { code: 'SHIB', amount: 10000, rotate: 315 },
            { code: 'SOL', amount: 30000, rotate: 157.5 },
            { code: 'TRX', amount: 50000, rotate: 270 },
            { code: 'DOGE', amount: 100000, rotate: 202.5 },
            { code: 'XRP', amount: 300000, rotate: 112.5 },
            { code: 'USDT', amount: 500000, rotate: 247.5 },
            { code: 'ETH', amount: 1000000, rotate: 90 },
            { code: 'BTC', amount: 3000000, rotate: 0 },
        ],
        MEGA: [
            { code: 'BCD', amount: 500, rotate: 45 },
            { code: 'BCD', amount: 500, rotate: 135 },
            { code: 'BCD', amount: 500, rotate: 225 },
            { code: 'BCD', amount: 500, rotate: 337.5 },
            { code: 'CRO', amount: 1000, rotate: 292.5 },
            { code: 'USDC', amount: 3000, rotate: 22.5 },
            { code: 'XLM', amount: 5000, rotate: 67.5 },
            { code: 'BNB', amount: 10000, rotate: 180 },
            { code: 'SHIB', amount: 30000, rotate: 315 },
            { code: 'SOL', amount: 50000, rotate: 157.5 },
            { code: 'TRX', amount: 100000, rotate: 270 },
            { code: 'DOGE', amount: 300000, rotate: 202.5 },
            { code: 'XRP', amount: 500000, rotate: 112.5 },
            { code: 'USDT', amount: 1000000, rotate: 247.5 },
            { code: 'ETH', amount: 3000000, rotate: 90 },
            { code: 'BTC', amount: 5000000, rotate: 0 },
        ]
    }

    swiperOptions!:SwiperOptions
    swiperItems:any = []
    constructor(
        private authService: AuthService,
        private modalService: ModalService,
        private apiService: ApiService,
        private spinService: SpinService
    ) {
        this.swiperOptions = {
            direction: "vertical",
            slidesPerView: 16,
            loop: true,
            autoplay:{
                delay: 500,
                reverseDirection: false,
                disableOnInteraction: false,
            },
        };
    }

    ngOnInit() {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v
            })
        )

        this.subs.push(
            this.onModalEvent.subscribe(v => {
                const { component, event, optParam } = v;
                if(event == 'close') {
                    // auto play
                    // if(this.currentUser) this.rollingSpin(optParam.spin)
                }
            })
        )

        this.fnGetData()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());

        this.spinService.add('1', 'hours')
    }

    ngAfterViewInit() {
        const els = document.querySelectorAll(`.spin-light`)

        setInterval(()=>{
            els.forEach(el => {
                el.classList.toggle('active')
            })
        }, 500)
    }

    fnGetData() {
        this.apiService.get('/spin/article', false).subscribe(jsondata => {
            if(jsondata.success) {
                this.swiperItems = []
                this.total_hit = jsondata.data.total_hit
                jsondata.data.items.map((v:any) => {
                    this.swiperItems.push({
                        nick: v.nick,
                        spin: v.spin,
                        amount: v.hit_amount,
                        prize: v.prize
                    })
                })

                this.swiperItems.push({ nick: 'test01', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test02', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test03', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test04', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test05', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test06', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test07', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test08', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test09', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test10', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test11', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test12', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test13', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test14', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test15', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test16', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test17', spin:'BTC', amount: 10000, prize: 'BTC'})
                this.swiperItems.push({ nick: 'test18', spin:'BTC', amount: 10000, prize: 'BTC'})
            }
        })
    }

    rollingSpin(name:string): void {
        if(!this.currentUser) this.modalService.open(null, 'sign-in', { spin: name }, this.onModalEvent)
        else this.play(name)
    }

    play(name:string): void {
        this.sigining = true;
        this.rolling(name)
    }

    rolling(name:string): void {
        this.spinService.add('+12', 'hours'); // 한번 돌렸으니 1시간 뒤에

        const el = document.querySelector(`.${name}`)
        if(el) {
            const zoom = el.querySelector('.zoom_box')
            if(zoom) zoom.classList.add('rolling')
        }

        setTimeout(() => {
            this.sigining = false;
            this.onClose.emit('close')
            const next = (name == "spin_diamond") ? `spin-rolling2` : `spin-rolling`
            this.modalService.open(null, next, { code:'BTC', amount: 1000 })
        },3500)
    }

    

    modalInHandle($event:any) {
        this.modalTab = (this.modalTab == $event) ? '' : $event;
    }
}

