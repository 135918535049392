<!-- 입금 보너스 모달 -->
<div class="modal-content overflow-hidden relative rounded">
    <div class="modal-body">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base">입금 보너스 규칙</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>

        <div class="w-full p-6 overflow-y-auto scrollbar h-auto sm:h-[640px]">
            <div>KBC.GAME은(는) 예외적인 4개의 보상 입금 매치 보너스를 제공합니다. 이것은 추가 Crypto를 획득하고 귀하의 재량에 따라 사용할 수 있는 고유한 Crypto 코인인 BC Dollar(BCD)를 획득할 수 있는 절호의 기회입니다. 따라서 이 환상적인 기회를 활용하여 KBC.GAME의 탁월한 보너스로 암호화폐 보유량을 늘리십시오.</div>
            
            <div class="grid grid-cols-2 gap-3 mt-4">
                <div class="rounded bg-backlight pb-3">
                    <div class="relative text-black">
                        <img src="/assets/custom_img/bonus/1st.webp" alt="">
                        <span class="absolute left-0 top-4 w-full text-center text-base font-bold">1st 입금</span>
                        <p class="absolute left-0 bottom-4 w-full text-center text-lg font-bold"><b class="block text-4xl">180%</b>보너스</p>
                    </div>
                    <div class="flex justify-between text-xs px-2">
                        <span>최소 입금</span>
                        <b class="text-tit font-bold">$ 10</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>획득 가능한 최대 수량</span>
                        <b class="text-tit font-bold">20,000 BCD</b>
                    </div>
                </div>
                <div class="rounded bg-backlight pb-3">
                    <div class="relative text-black">
                        <img src="/assets/custom_img/bonus/2nd.webp" alt="">
                        <span class="absolute left-0 top-4 w-full text-center text-base font-bold">2nd 입금</span>
                        <p class="absolute left-0 bottom-4 w-full text-center text-lg font-bold"><b class="block text-4xl">240%</b>보너스</p>
                    </div>
                    <div class="flex justify-between text-xs px-2">
                        <span>최소 입금</span>
                        <b class="text-tit font-bold">$ 50</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>획득 가능한 최대 수량</span>
                        <b class="text-tit font-bold">40,000 BCD</b>
                    </div>
                </div>
                <div class="rounded bg-backlight pb-3">
                    <div class="relative text-black">
                        <img src="/assets/custom_img/bonus/3rd.webp" alt="">
                        <span class="absolute left-0 top-4 w-full text-center text-base font-bold">3rd 입금</span>
                        <p class="absolute left-0 bottom-4 w-full text-center text-lg font-bold"><b class="block text-4xl">300%</b>보너스</p>
                    </div>
                    <div class="flex justify-between text-xs px-2">
                        <span>최소 입금</span>
                        <b class="text-tit font-bold">$ 100</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>획득 가능한 최대 수량</span>
                        <b class="text-tit font-bold">60,000 BCD</b>
                    </div>
                </div>
                <div class="rounded bg-backlight pb-3">
                    <div class="relative text-black">
                        <img src="/assets/custom_img/bonus/4th.webp" alt="">
                        <span class="absolute left-0 top-4 w-full text-center text-base font-bold">4th 입금</span>
                        <p class="absolute left-0 bottom-4 w-full text-center text-lg font-bold"><b class="block text-4xl">360%</b>보너스</p>
                    </div>
                    <div class="flex justify-between text-xs px-2">
                        <span>최소 입금</span>
                        <b class="text-tit font-bold">$ 200</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>획득 가능한 최대 수량</span>
                        <b class="text-tit font-bold">100,000 BCD</b>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
