<!-- 최신베팅&레이스 > 롤링대회 > 내역 모달 -->
<div class="modal-content">
    <div class="modal-body bg-stand relative rounded">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-3">
                <p class="text-tit font-extrabold text-base">내역</p>
                <span>2023. 12. 1. ~ 2023. 12. 2.</span>
            </div>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-5">
            <div class="overflow-x-auto scrollbar_x">
                <table class="table table-hover noline mo_pad-s rounded min-w-[580px]">
                    <thead class="text-center text-xs text-sub">
                        <tr>
                            <th class="whitespace-nowrap text-left">#</th>
                            <th class="whitespace-nowrap">플레이어</th>
                            <th class="whitespace-nowrap">롤링된</th>
                            <th class="whitespace-nowrap text-right">상품/상금</th>
                        </tr>
                    </thead>
                    <tbody class="font-extrabold text-center cursor-pointer">
                        <tr>
                            <td class="text-left font-normal"><img class="w-5" src="/assets/custom_img/chat_gold.svg" /></td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img.png"/><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: 1 })">M■■</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal"><img class="w-5" src="/assets/custom_img/chat_silver.svg" /></td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img2.png"/><b class="text-tit font-extrabold" data-tw-toggle="modal" data-tw-target="#profile_info-modal">NotFrank</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal"><img class="w-5" src="/assets/custom_img/chat_copper.svg" /></td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img3.png"/><b class="font-extrabold"><svg class="inline-flex w-3.5 h-3.5 mb-0.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Hidden"></use></svg> 숨기기</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">4th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img3.png"/><b class="font-extrabold"><svg class="inline-flex w-3.5 h-3.5 mb-0.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Hidden"></use></svg> 숨기기</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">5th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img.png"/><b class="text-tit font-extrabold" data-tw-toggle="modal" data-tw-target="#profile_info-modal">M■■</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">6th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img2.png"/><b class="text-tit font-extrabold" data-tw-toggle="modal" data-tw-target="#profile_info-modal">NotFrank</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">7th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img3.png"/><b class="font-extrabold"><svg class="inline-flex w-3.5 h-3.5 mb-0.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Hidden"></use></svg> 숨기기</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">8th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img.png"/><b class="text-tit font-extrabold" data-tw-toggle="modal" data-tw-target="#profile_info-modal">M■■</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">9th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img2.png"/><b class="text-tit font-extrabold" data-tw-toggle="modal" data-tw-target="#profile_info-modal">NotFrank</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">10th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img3.png"/><b class="font-extrabold"><svg class="inline-flex w-3.5 h-3.5 mb-0.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Hidden"></use></svg> 숨기기</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
